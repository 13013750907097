@font-face {
	font-family: Raleway;
	src: url("../assets/Raleway-VariableFont_wght.ttf");
}

.loading__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #2e343b;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
      position: relative;
}

.loading__blur {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: rgba(255,255,255,0.1);
      z-index: 2;
}

.loading__containerBox {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 40vw;
      height: 40vh;
      padding: 1rem;
      background-color: #24292e;
      z-index: 3;
}

.loading__containerBox > h1 {
      font-family: 'Raleway', sans-serif;
      font-size: 2rem;
      font-weight: bold;
      color: white;
      letter-spacing: 1px;
      text-transform: uppercase;
}

.loading__containerBox > form {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 80%;
      height: 60%;
}

.loading__containerBox > form > input, button {
      padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
      color: white;
	font-weight: 500;
	letter-spacing: 2px;
	border: 1px solid #24292e;
      width: 100%;
      overflow: hidden;
}

.loading__containerBox > form > button {
      width: 50%;
      transition: .2s all;
}

.loading__containerBox > form > button:hover {
      transform: scale(.9);
}

.loading__containerBox > form > button:active {
      transform: scale(1.1);
}