.cursor {
      width: 60px;
      height: 60px;
      max-width: 150px;
      max-height: 150px;
      border: 6px solid #7FFF00;
      background-color: rgba(240, 240, 240, .4);
      border-radius: 50%;
      pointer-events: none;
      transition: all 0.1s ease;
      transition-property: background, transform;
      transform-origin: 150% 150%;
      z-index: 999;
      cursor: none;
      position: relative;
      transform: translateY(-50%) translateX(-50%);
}

.cursor > div {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #7FFF00;
      position: absolute;
      top: calc(50% - 3px);
      left: calc(50% - 3px);
}

/*
      width: 9rem;
      height: 12rem;
      position: relative;
      background-image: url('../assets/CustomCursor.png');
      background-repeat: no-repeat;
      background-size: contain; 
      transform: translateX(-50%) translateY(-5%);
      pointer-events: none;
      transition: all .5s ease;
      transition-property: background, transform;
      transform-origin: 150% 150%;
      z-index: 999;
      cursor: none;
*/