body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dartboard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(3000px / 4.5 );
      height: calc(3000px / 4.5 );
      border-radius: 50%;
      position: relative;
      z-index: 893;
}

.mid {
      width: 0;
      height: 0;
      border-left: calc(110px / 4.5 ) solid transparent;
      border-right: calc(110px / 4.5 ) solid transparent;
      z-index: 897;
      position: absolute;
      transform-origin: bottom center;
      border-top: calc(700px / 4.5 ) solid transparent;
}

.triple {
      width: 0;
      height: 0;
      border-left: calc(120px / 4.5 ) solid transparent;
      border-right: calc(120px / 4.5 ) solid transparent;
      z-index: 896;
      position: absolute;
      transform-origin: bottom center;
      border-top: calc(750px / 4.5 ) solid transparent;
}

.long {
      width: 0;
      height: 0;
      border-left: calc(175px / 4.5 ) solid transparent;
      border-right: calc(175px / 4.5 ) solid transparent;
      z-index: 895;
      position: absolute;
      transform-origin: bottom center;
      border-top: calc(1100px / 4.5) solid transparent;  
}

.double {
      width: 0;
      height: 0;
      border-left: calc(185px / 4.5 ) solid transparent;
      border-right: calc(185px / 4.5) solid transparent;
      z-index: 894;
      position: absolute;
      transform-origin: bottom center;
      border-top: calc(1150px / 4.5) solid transparent;  
}

.text {
      height: calc(1350px / 4.5);
      width: calc(370px / 4.5 );
      color: white;
      text-align: center;
      font-size: calc(10rem / 4.5);
      font-family: sans-serif;
      z-index: 893;
      position: absolute;
      transform-origin: bottom center;
}

.zero {
      width: 0;
      height: 0;
      border-left:  calc(235px / 4.5 ) solid transparent;
      border-right: calc(235px / 4.5 ) solid transparent;
      z-index: 892;
      position: absolute;
      transform-origin: bottom center;
      border-top: calc(1450px / 4.5 ) solid transparent;     
}



.black {
      border-top-color: #2e343b
}
.yellow {
      border-top-color: #fce1ce;
}
.green {
      border-top-color: #578b6b;
}
.red {
      border-top-color: #d5574f
}

.r50 {
      width: calc(75px / 4 );
      height: calc(75px / 4 );
      border-radius: 50%;
      background-color: #d5574f; 
      position: absolute;
      z-index: 899;
 }
 
 .g25 {
       width: calc(180px / 4 );
       height: calc(180px / 4 );
       border-radius: 50%;
       background-color: #578b6b; 
       position: absolute;
       z-index: 898;
 }

.mid1 {
      transform: translateY(-50%) rotate(18deg); 
}

.mid20 {
      transform: translateY(-50%);

}

.mid18 {
      transform: translateY(-50%) rotate(36deg);
}

.mid4 {
      transform: translateY(-50%) rotate(54deg); 
}

.mid13 {
      transform: translateY(-50%) rotate(72deg); 
}

.mid6 {
      transform: translateY(-50%) rotate(90deg); 
}

.mid10 {
      transform: translateY(-50%) rotate(108deg); 
}

.mid15 {
      transform: translateY(-50%) rotate(126deg); 
}

.mid2 {
      transform: translateY(-50%) rotate(144deg); 
}

.mid17 {
      transform: translateY(-50%) rotate(162deg); 
}

.mid3 {
      transform: translateY(-50%) rotate(180deg); 
} 

.mid19 {
      transform: translateY(-50%) rotate(198deg); 
} 

.mid7 {
      transform: translateY(-50%) rotate(216deg); 
}

.mid16 {
      transform: translateY(-50%) rotate(234deg); 
}

.mid8 {
      transform: translateY(-50%) rotate(252deg); 
}

.mid11 {
      transform: translateY(-50%) rotate(270deg); 
}

.mid14 {
      transform: translateY(-50%) rotate(288deg); 
}

.mid9 {
      transform: translateY(-50%) rotate(306deg); 
}

.mid12 {
      transform: translateY(-50%) rotate(324deg); 
}

.mid5 {
      transform: translateY(-50%) rotate(342deg); 
}
.cursor {
      width: 60px;
      height: 60px;
      max-width: 150px;
      max-height: 150px;
      border: 6px solid #7FFF00;
      background-color: rgba(240, 240, 240, .4);
      border-radius: 50%;
      pointer-events: none;
      transition: all 0.1s ease;
      transition-property: background, transform;
      transform-origin: 150% 150%;
      z-index: 999;
      cursor: none;
      position: relative;
      transform: translateY(-50%) translateX(-50%);
}

.cursor > div {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #7FFF00;
      position: absolute;
      top: calc(50% - 3px);
      left: calc(50% - 3px);
}

/*
      width: 9rem;
      height: 12rem;
      position: relative;
      background-image: url('../assets/CustomCursor.png');
      background-repeat: no-repeat;
      background-size: contain; 
      transform: translateX(-50%) translateY(-5%);
      pointer-events: none;
      transition: all .5s ease;
      transition-property: background, transform;
      transform-origin: 150% 150%;
      z-index: 999;
      cursor: none;
*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}

.loading__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #2e343b;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
      position: relative;
}

.loading__blur {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: rgba(255,255,255,0.1);
      z-index: 2;
}

.loading__containerBox {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 40vw;
      height: 40vh;
      padding: 1rem;
      background-color: #24292e;
      z-index: 3;
}

.loading__containerBox > h1 {
      font-family: 'Raleway', sans-serif;
      font-size: 2rem;
      font-weight: bold;
      color: white;
      letter-spacing: 1px;
      text-transform: uppercase;
}

.loading__containerBox > form {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 80%;
      height: 60%;
}

.loading__containerBox > form > input, button {
      padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
      color: white;
	font-weight: 500;
	letter-spacing: 2px;
	border: 1px solid #24292e;
      width: 100%;
      overflow: hidden;
}

.loading__containerBox > form > button {
      width: 50%;
      transition: .2s all;
}

.loading__containerBox > form > button:hover {
      transform: scale(.9);
}

.loading__containerBox > form > button:active {
      transform: scale(1.1);
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
.app__container {
      display: flex;
      flex: 1 1;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #24292e;
}

.app__dartBoardContainer {
      display: flex;
      flex: .6 1;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100vh;
      position: relative;
      background-color: #37414d;
}

.dartBoardContainer__dartBoard {
      display: flex;
      height: 75vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}

.dartBoardContainer__darts {
      display: flex;
      flex: 1 1;
      height: calc(25vh - 4rem); 
      width: calc(100% - 8rem);
      padding: 2rem 4rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 75%;
      border-top-right-radius: 75%;
} 

.dartBoardContainer__darts > div {
      display: flex;
      flex: .3 1;
      height: 100%;
      background-color: #4d5259;
}

.dartBoardContainer__darts > div > h1 {
      width: 100%;
      height: 100%;
      color: white;
      font-family: sans-serif;
      font-weight: bold;
      text-align: center;
      line-height: 2.7;
      font-size: 4rem;
      background-color: #2e343b;
}

.app__controlBoard {
      display: flex;
      flex: .4 1;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: calc(100vh - 4rem);
      padding: 2rem;
}

.app__controlBoard > h1 {
      color: white;
      font-family: sans-serif;
      font-size: 4rem;
      text-align: left;
      width: 100%;
}

.controlBoard__score {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 4rem);
      height: calc(15vh - 4rem);
      margin-top: 4vh;
      padding: 2rem;
      background-color: #272c31;
}

.controlBoard__score > h1 {
      color: white;
      font-family: sans-serif;
      font-size: 2.6rem;
      text-align: left;
}

.controlBoard__data {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 4vh;
      width: 100%;
      height: 45vh;
      background-color: #272c31;
}

.controlBoardData__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 4rem);
      height: calc(15vh - 4rem);
      padding: 2rem;
}

.controlBoard__buttons {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 15vh;
      margin-top: 4vh;
}

.controlBoardButtons__reset {
      display: flex;
      flex: .3 1;
      justify-content: center;
      align-items: center;
      background-color: #d5574f;
      height: 90%;
      cursor: pointer;
      transition: .2s all;
}

.controlBoardButtons__round {
      display: flex;
      flex: .3 1;
      justify-content: center;
      align-items: center;
      background-color: #578b6b;
      height: 90%; 
      cursor: pointer;
      transition: .2s all;
}

.controlBoardButtons__round:hover, .controlBoardButtons__reset:hover {
      transform: scale(.9);
}

.controlBoardButtons__round:active, .controlBoardButtons__reset:active {
      transform: scale(1.1);
}

.controlBoard__buttons > div > h1 {
      color: white;
      font-family: sans-serif;
      font-size: 2rem;
      text-align: center;
}

.controlBoardData__row > h1 {
      color: white;
      font-family: sans-serif;
      font-size: 2.6rem;
      text-align: left;
}




.cursor {
      position: absolute;
}

.cross {
      position: absolute;
      width: 10px;
      height: 10px;
      border: 1px solid red;
      border-radius: 50%;
      z-index: 999;
      transform: translateX(-50%) translateY(-50%);
}

